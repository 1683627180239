import { useBreakpoint } from '@/shared/hooks/useBreakpoints';
import { AnimatedTitle } from '@/shared/ui/animated';
import { Typography } from '@/shared/ui/typography';
import { useMain } from '@/store/api';
import { ContactForm } from '@/widgets/contactForm';
import { motion, useInView } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { useRef } from 'react';

export const ContactUs = () => {
  const { contact_us } = useMain();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const { isDesktop } = useBreakpoint();
  return (
    <section className="mb:my-16 mb:max-tb:flex-col tb:max-dk:my-[84px] dk:mt-[119px] dk:mb-[116px] container flex">
      <Typography
        ref={ref}
        variant="h2"
        className="mb:max-tb:mb-9 dk:w-[650px] tb:w-[440px] flex flex-col uppercase"
      >
        <motion.span
          className="flex w-full"
          initial={{ clipPath: 'inset(0 100% 0 0)' }}
          animate={{
            clipPath: isInView ? 'inset(0 0 0 0)' : 'inset(0 100% 0 0)',
          }}
          transition={{ duration: 1 }}
        >
          <AnimatedTitle
            isInView={isInView}
            desktop={{ initialX: 110 }}
            tablet={{ initialX: 70 }}
            delay={1}
            className="dk:leading-[90px]"
          >
            {contact_us?.title_1}
          </AnimatedTitle>
        </motion.span>
        <motion.span
          className="flex w-full "
          initial={{ clipPath: 'inset(0 100% 0 0)' }}
          animate={{
            clipPath: isInView ? 'inset(0 0 0 0)' : 'inset(0 100% 0 0)',
          }}
          transition={{ duration: 1 }}
        >
          <AnimatedTitle
            isInView={isInView}
            desktop={{ initialX: -92 }}
            tablet={{ initialX: -79 }}
            className="mb:ml-0 mb:self-start tb:self-center tb:ml-[192px] dk:ml-[263px]"
            delay={1}
          >
            {contact_us?.title_2}
          </AnimatedTitle>
        </motion.span>
      </Typography>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.7, delay: 1 }}
        viewport={{ once: true }}
        className="dk:pt-[4px]"
      >
        <ContactForm
          classNames={{
            form: 'dk:gap-[21px] tb:gap-[22px]',
            tabs: 'dk:mb-[25px]',
          }}
          //title={t('forms.contact_us.services')}
          //buttonText="forms.cv.button_text"
        />
      </motion.div>
    </section>
  );
};
