import { Snackbar } from '@/shared/ui/snackbar';
import { Button } from '../../shared/ui/button';
import { Checkbox, FileInput, Input, PhoneInput } from '../../shared/ui/input';
import { Tab } from '../../shared/ui/tab';
import { Typography } from '../../shared/ui/typography';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { useState, Fragment, useId, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useContactUsMutation, useLayout } from '@/store/api';

type FormValues = {
  email?: string;
  name?: string;
  description?: string;
  phone?: string;
  file?: any;
};

export const ContactForm = ({
  title,
  subtitle,
  classNames,
  items,
  buttonText,
  policyWidth,
}: {
  title?: string | null;
  subtitle?: string;
  classNames?: {
    form?: string;
    container?: string;
    title?: string;
    tabs?: string;
    inputs?: [string, string, string];
  };
  items?: string[];
  buttonText?: string;
  policyWidth?: string;
}) => {
  const { forms } = useLayout();
  const { t } = useTranslation('common');
  const tabs: string[] = useMemo(
    () =>
      items ?? forms?.contact_us?.tabs?.map(({ title }) => title || '') ?? [],
    [items],
  );

  const [submit, { isLoading }] = useContactUsMutation();

  const formId = useId();

  const [activeTab, setActiveTab] = useState<number | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setError,
    control,
    watch,
    reset,
  } = useForm<FormValues>({
    mode: 'onChange',
  });

  const [policyChecked, setPolicyChecked] = useState(false);

  const [submitted, setSubmitted] = useState(false); // Нужно для понимание в PhoneInput об отправке формы
  // const [valid, setValid] = useState(false); // Нужно для проверки валидности введенных в номер телефона данных
  // const [phoneNumber, setPhoneNumber] = useState(''); // Сам номер телефона

  // const [fileName, setFileName] = useState('');
  const onSubmit = async (data: FormValues) => {
    try {
      await submit({
        ...data,
        ...(activeTab && { interest: tabs[activeTab] }),
      });
    } catch (e: any) {
      console.log(e);
    } finally {
      setPolicyChecked(false);
      setActiveTab(null);
      setSubmitted(true);

      reset();
    }

    // setSubmited(true);
    // if (valid) {
    //   reset(); // Для очистки формы после отправки
    //   setPhoneNumber(''); // Для очистки PhoneInput после отправки
    //   setSubmited(false); // Для повторной отправки, чтобы ошибки на PhoneInput сразу же не сыпались
    // }
    // setFileName('');
  };

  // Здесь он ругается на первую зависимость, но без нее при отправке формы ошибка на номере телефона не высвечивается (если она есть)
  // useEffect(() => {
  //   if (Object.keys(errors).length > 0) {
  //     setSubmited(true); // Для того чтобы ошибка в PhoneInput тоже выводилась
  //   }
  // }, [Object.keys(errors).length, errors]);

  return (
    <>
      <div
        className={cn(
          'tb:max-w-[440px] dk:max-w-[36.5rem] flex flex-col',
          classNames?.container,
        )}
      >
        <Typography
          variant="titleL"
          className={cn('mb:max-tb:text-titleM', classNames?.title)}
        >
          {title ?? forms?.contact_us?.services}
        </Typography>
        {subtitle && (
          <Typography variant="textM" className="text-gray-3">
            {subtitle}
          </Typography>
        )}
        <div
          className={cn(
            classNames?.tabs,
            'gap tb:mb-[1.6rem] dk:mb-5 mb-[1.5rem] mt-6 flex flex-wrap gap-1',
          )}
        >
          {tabs.map((item, index) => (
            <Fragment key={item}>
              <Tab
                className="bg-gray-5"
                isActive={index === activeTab}
                onClick={() => setActiveTab(index)}
              >
                {item}
              </Tab>
            </Fragment>
          ))}
          {items &&
            items.map((item, index) => (
              <Fragment key={item}>
                <Tab
                  className="bg-gray-5 text-start"
                  isActive={index === activeTab}
                  onClick={() => setActiveTab(index)}
                >
                  {item}
                </Tab>
              </Fragment>
            ))}
        </div>
        <form
          id={formId}
          onSubmit={handleSubmit(onSubmit)}
          className={cn(
            classNames?.form,
            'mb:max-tb:mb-[1.65rem] tb:max-dk:mb-6 mb:max-tb:gap-[1.3rem] mb-11 flex flex-col gap-9',
          )}
        >
          <Input
            {...register('name', {
              required: t('forms.errors.name.required') ?? 'Имя обязательно',
              validate: {
                maxLength: (v) =>
                  v!.length <= 255 ||
                  (t('forms.errors.name.maxLength') ??
                    'Maximum number of characters 255'),
              },
            })}
            className={classNames?.inputs?.[0]}
            label={forms?.contact_us?.name ?? ''}
            errorMessage={errors.name?.message}
          />
          <Input
            {...register('email', {
              required: t('forms.errors.email.required') ?? 'Email обязателен',
              validate: {
                maxLength: (v) =>
                  v!.length <= 255 ||
                  (t('forms.errors.email.maxLength') ??
                    'Maximum number of characters 255'),
                matchPattern: (v) =>
                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(v ?? '') ||
                  (t('forms.errors.email.matchPattern') ??
                    'Email должен быть действительным'),
              },
            })}
            errorMessage={errors.email?.message}
            className={classNames?.inputs?.[1]}
            label={forms?.contact_us?.email ?? ''}
          />
          <Input
            {...register('description', {
              validate: {
                maxLength: (v) =>
                  v!.length <= 4096 ||
                  (t('forms.errors.description.maxLength') ??
                    'Maximum number of characters 4096'),
              },
            })}
            errorMessage={errors.description?.message}
            className={classNames?.inputs?.[2]}
            label={forms?.contact_us?.description ?? ''}
          />
          {/* <PhoneInput
        submited={submited}
        valid={valid}
        setValid={setValid}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        placeholder="+7 ___ ___ __ __"
        label="Your phone number"
      /> */}

          {/* <FileInput
        {...register('file', {
          onChange: (event: ChangeEvent<HTMLInputElement>) => {
            const files = event.target.files?.[0];
            files && setFileName(files.name);
          },
          validate: {
            emptyFile: (files) =>
              files[0]?.size > 0 || `You must attach a file`,
            lessThan25MB: (files) =>
              files[0]?.size < 25000000 || `Max size of file 25Mb`,
          },
        })}
        setError={setError}
        errorMessage={errors.file?.message as string}
        fileName={fileName}
      /> */}
        </form>
        <div className="dk:gap-[26px] mb:max-dk:gap-3 mb:max-dk:flex-col-reverse flex items-start">
          <Button
            form={formId}
            type="submit"
            isCapsVariant
            variant="filled"
            className="flex-auto-0 uppercase"
            disabled={!isValid || !policyChecked}
          >
            {buttonText || forms?.contact_us?.button_text}
          </Button>
          <div className="tb:max-dk:gap-2.5 tb:max-dk:pl-[4px] flex gap-2">
            <div className="tb:max-dk:pt-0.5 mb:max-tb:pl-1 pt-[3px]">
              <Checkbox
                policyChecked={policyChecked}
                setPolicyChecked={setPolicyChecked}
              />
            </div>
            <Typography
              variant="textS"
              className={cn('text-gray-3 dk:p-[3px]', policyWidth)}
            >
              {forms?.contact_us?.agreement}
            </Typography>
          </div>
        </div>
      </div>
      <Snackbar setSubmitted={setSubmitted} submitted={submitted} />
    </>
  );
};
