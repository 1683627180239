import cn from 'classnames';

interface Props {
  children: React.ReactNode;
  isActive: boolean;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
}

export const Tab = ({
  disabled = false,
  children,
  className,
  isActive,
  onClick,
}: Props) => {
  return (
    <button
      className={cn(
        'text-textButton border px-4 py-[9px] text-start font-semibold transition-all duration-100',
        className,
        { 'border-dark text-dark border': isActive },
        { 'hover:border-gray-3 text-gray-1 border-black/0': !isActive },
        { 'text-gray-3 pointer-events-none border-black/0': disabled },
      )}
      onClick={() => onClick?.()}
    >
      {children}
    </button>
  );
};
